// palette for Paradise8 Casino
$bg-color: #1a0046;
$text-color: #ded0fa;
$primary: #04cca4;
$success: #04CC60;
$info: #6404fc;
$danger: #CC1604; //todo



$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}


#promos {
  img {
    width: 150% !important;
  }
}
